import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import { Link } from "gatsby";
import {
  FaCalendar,
  FaUsers,
  FaChartLine,
  FaFolder,
  FaEdit,
  FaListOl,
  FaMale,
  FaList
} from "react-icons/fa";
import styles from "./pomoc.module.css";
import Container from "../components/container";
import SEO from "../components/seo";

const Pomoc = () => {
  return (
    <Layout>
      <SEO
        title="Pomoc - poznaj możliwości prostego systemu CRM "
        description="Pomoc • Jak wykorzystać funkcje systemu CRM, aby przyniósł on firmie jak najwięcej korzyści? • Wypróbuj QuickCRM i zacznij sprzedawać jak nigdy wcześniej!"
      />
      <Hero maxHeight="280px" justifyContent="center">
        <h1>Pomoc</h1>
        <p>Szukasz odpowiedzi? Chcesz poznać możliwości systemu?</p>
      </Hero>
      <Container>
        <div className={styles.helpMenu}>
          <div>
            <Link to="/pomoc/zadania-i-zdarzenia/">
              <FaCalendar size="35px" />
              <p>Zadania i zdarzenia</p>
            </Link>
          </div>
          <div>
            <Link to="/pomoc/kontrahenci/">
              <FaUsers size="35px" />
              <p>Kontrahenci</p>
            </Link>
          </div>
          <div>
            <Link to="/pomoc/szanse-sprzedazy/">
              <FaChartLine size="35px" />
              <p>Kampanie sprzedażowe</p>
            </Link>
          </div>
          <div>
            <Link to="/pomoc/import-danych/">
              <FaFolder size="35px" />
              <p>Import danych</p>
            </Link>
          </div>
          <div>
            <Link to="/pomoc/dynamiczny-pulpit/">
              <FaEdit size="35px" />
              <p>Dynamiczny pulpit</p>
            </Link>
          </div>
          <div>
            <Link to="/pomoc/lista-aktywnosci/">
              <FaListOl size="35px" />
              <p>Lista aktywności</p>
            </Link>
          </div>
          <div>
            <Link to="/pomoc/profil-pracownika/">
              <FaMale size="35px" />
              <p>Ustawienia profilu</p>
            </Link>
          </div>
          <div>
            <Link to="/pomoc/operacje-masowe/">
              <FaList size="35px" />
              <p>Operacje masowe</p>
            </Link>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Pomoc;
